export default {
  'stationAmercement.components.maintOrderNo': '维保单号',
  'stationAmercement.components.amerceCreatedTime': '扣款扣缴时间',
  'stationAmercement.components.amerceAmount': '扣款金额',
  'stationAmercement.components.deductedAmount': '抵扣扣款',
  'stationAmercement.components.remainingAmerceAmount': '剩余扣款',
  'stationAmercement.components.amerceStatus': '状态',
  'stationAmercement.components.image': '图片',
  'stationAmercement.components.preview': '预览',
  'stationAmercement.components.amercementReason': '扣款原因',
};
