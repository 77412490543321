export default {
  'stationMonthlyStatement.components.confirmedVoucherFilesLabel':
    'Stamped {textConfirmedVoucherFiles}photos',
  'stationMonthlyStatement.components.maxOtherVoucherFilesVerifyMessage':
    '{textOtherVoucherFiles} cannot exceed {maxOtherVoucherFiles}.',
  'stationMonthlyStatement.components.maxInvoiceVoucherFilesVerifyMessage':
    '{textInvoiceVoucherFiles} cannot exceed {maxInvoiceVoucherFiles}.',
  'stationMonthlyStatement.components.maxPhotoVerifyMessage':
    'The number of stamped photos{textConfirmedVoucherFiles} cannot exceed {maxConfirmedVoucherFiles}.',
  'stationMonthlyStatement.components.settlementAmount':
    'Settlement Amount ({unit})',
  'stationMonthlyStatement.components.waitUnsettledAmount':
    'Amount to be settled ({unit})',
  'stationMonthlyStatement.components.totalSettledAmount':
    'Total Settlement Amount ({unit})',
  'stationMonthlyStatement.components.carRepairAmount': 'Repair Amount',
  'stationMonthlyStatement.components.fittingDiscountFee': 'Accessory Credit',
  'stationMonthlyStatement.components.marginAmount': 'Margin',
  'stationMonthlyStatement.components.amercement': 'Deduction',
  'stationMonthlyStatement.components.settlementSituation': 'Settlement Status',
  'stationMonthlyStatement.components.estimatedSettlementDate':
    'Estimated Settlement Date',
  'stationMonthlyStatement.components.maintenanceSummary': 'Repair Summary',
  'stationMonthlyStatement.components.marginAmountStatus': '(Margin {label})',
  'stationMonthlyStatement.components.repairTimes': 'Number of repairs',
  'stationMonthlyStatement.components.otherFeeRemarkLabel': 'Other Fee Remarks',
  'stationMonthlyStatement.components.repairAmount': 'Repair Amount ({unit})',
  'stationMonthlyStatement.components.settledAmount': 'Settled Amount ({unit})',
  'stationMonthlyStatement.components.settlementRatio': 'Settlement Ratio',
  'stationMonthlyStatement.components.commissionAmount':
    'Commission Amount ({unit})',
  'stationMonthlyStatement.components.unsettledAmount':
    'Unsettled amount ({unit})',
  'stationMonthlyStatement.components.marginAmountDeduct':
    'Amount of security deposit to be withheld ({unit})',
  'stationMonthlyStatement.components.directSupplyFittingFee':
    'Purchase cost of directly supplied accessory ({unit})',
  'stationMonthlyStatement.components.directSupplyFittingDiscountFee':
    'Offsetting the cost of directly supplied accessory ({unit})',
  'stationMonthlyStatement.components.directSupplyFittingDescription':
    '（Balance brought forward: {historyAmount}，Balance added this month:{currentAmount}）',
  'stationMonthlyStatement.components.amercementFee':
    'Amount of deduction ({unit})',
  'stationMonthlyStatement.components.amercementFeeDescription':
    '（Balance brought forward: {historyAmount}，Balance added this month:{currentAmount}）',
  'stationMonthlyStatement.components.otherFee': 'Other expenses ({unit})',
  'stationMonthlyStatement.components.finalSettleAmount':
    'The out-of-pocket amount ({unit})',
  'stationMonthlyStatement.components.finalOtherSettleAmount':
    '（AnNeng Settle Amount：{finalSpecialSettleAmount}，Other Settle Amount：{finalOtherSettleAmount}）',
  'stationMonthlyStatement.components.invoiceMessagePartOne':
    'Please invoice the amount of ',
  'stationMonthlyStatement.components.invoiceMessagePartTwo':
    'For invoices regarding accessories purchased by your company, please contact the Channel Manager.',
  'stationMonthlyStatement.components.invoiceInfo': 'Invoicing Information',
  'stationMonthlyStatement.components.invoiceAmount':
    'Invoicing amount ({unit})',
  'stationMonthlyStatement.components.companyName': 'Company Name',
  'stationMonthlyStatement.components.taxNumber': 'Tax code',
  'stationMonthlyStatement.components.address': 'Address',
  'stationMonthlyStatement.components.mobileNumber': 'Phone number',
  'stationMonthlyStatement.components.depositBank': 'Bank',
  'stationMonthlyStatement.components.bankAccount': 'Bank Account Number',
  'stationMonthlyStatement.components.invoiceConfirmationMailingTitle':
    'Invoice and confirmation letter mailing information',
  'stationMonthlyStatement.components.mailingAddress': 'Mailing Address',
  'stationMonthlyStatement.components.addressee': 'Recipient',
  'stationMonthlyStatement.components.settledTime': 'Settlement Date',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartOne':
    'Hello, please confirm carefully:',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartTwo':
    '(i) As of {filterToTheLastDayOfCurrentMonth}, the total amount of repair fee that should be settled by Shinwell is {unit}{filterRepairFee}, and there is no unsettled repair bill.',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartThree':
    '(ii) According to the communication, {filterToChineseDate} the amount of direct supply accessories purchased {unit}{filterDirectSupplyFittingFee}, the amount of direct supply accessories purchased that should be offset from the maintenance fee is {unit}{ filterDirectSupplyFittingDiscountFee}.',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartFour':
    '(iii) {filterToChineseDate} incurs a penalty amount of {unit}{filterAmercementFee}.',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartFive':
    '(iv) The out-of-pocket amount of {filterToChineseDate} is {unit}{filterFinalSettleAmount}.',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartSix':
    'Once confirmed, Shinwell will no longer be liable for and pay for repairs made prior to the Closing Date outside the scope of this Settlement Statement.',
  'stationMonthlyStatement.components.stationMonthlyStatementStationBasicInfoTip': `Shinwell's Finance will update it upon receipt of stamped confirmation letter and invoice`,
  'stationMonthlyStatement.components.settlementVoucherTitle':
    'Settlement Voucher',
  'stationMonthlyStatement.components.otherSettlementVoucher': 'Other Vouchers',
  'stationMonthlyStatement.components.invoice': 'Invoice',
  'stationMonthlyStatement.components.confirmationLetter':
    'Confirmation Letter',
  'stationMonthlyStatement.components.confirm': 'Confirmation',
  'stationMonthlyStatement.components.statementTime': 'Statement Time',
  'stationMonthlyStatement.components.channelManagerUser': 'Channel Manager',
  'stationMonthlyStatement.components.invoicingAbility': 'Invoicing Capability',
  'stationMonthlyStatement.components.taxRate': 'Tax Rate',
  'stationMonthlyStatement.components.uploadingSettlementSucceeded':
    'Upload Settlement Voucher Successfully',
  'stationMonthlyStatement.components.pleaseUpload': 'Please upload ',
  'stationMonthlyStatement.components.textInvoiceVoucherFiles':
    'Invoice photo or PDF',
  'stationMonthlyStatement.components.contactPerson': 'Contact Person',
  'stationMonthlyStatement.components.stationInformation':
    'Station Information',
  'stationMonthlyStatement.components.stationName': 'Station Name',
};
