import { h } from 'vue';

import axios from 'axios';
import { debounce } from 'lodash-es';
import stringify from 'qs/lib/stringify';

import { message } from 'ant-design-vue';
import 'ant-design-vue/es/message/style/index.js';

import { useUserStore } from '@/stores/user.js';
import router from '@/router';
import i18n, { I18nLanguage } from '@/locales/locales.js';

import { contentType, requestTimeout, successCode, tokenName } from '@/config';

import { removeAccessToken } from '@/utils/accessToken';

import { RequestHeaderLocale } from '@/constants/option-value.js';

/**
 * 根据当前应用内设置的语言，获取不同类型的国际化请求头信息。
 * @param {string} currentLocale 当前应用内设置的语言
 * @param {string} 最终国际化请求头信息
 */
export function getRequestHeaderLocale(currentLocale) {
  switch (currentLocale) {
    case I18nLanguage.EN_US:
      return RequestHeaderLocale.EN_US;

    case I18nLanguage.ZH_CN:
      return RequestHeaderLocale.ZH_CN;

    default:
      return RequestHeaderLocale.ZH_CN;
  }
}

// loading对象
let loading = null;
// 当前正在请求的数量
let loadingRequestCount = 0;

// 将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题
const toHideLoading = debounce(
  () => {
    loading();
    loading = null;
  },
  300,
  { leading: true },
);

// 显示loading
function showLoading() {
  if (loadingRequestCount === 0 && !loading) {
    loading = message.loading(`${i18n.global.t('app.common.loading')}...`, 0);
  }
  loadingRequestCount = loadingRequestCount + 1;
}

// 隐藏loading
function hideLoading() {
  loadingRequestCount = loadingRequestCount - 1;
  loadingRequestCount = Math.max(loadingRequestCount, 0);
  if (loadingRequestCount === 0 && loading) {
    // 关闭loading
    toHideLoading();
  }
}

/**
 * 处理code异常
 * @param {Number} status Http Status Code
 * @param {String} code 业务异常Code
 * @param {String} msg 业务异常信息
 * @param {boolean} hideError 是否隐藏错误信息
 * @param {String} traceId 业务异常追踪ID
 * @param {String} trace 业务异常信息
 */
const handleCode = (status, code = '', msg, hideError, traceId, trace) => {
  let routerPath = null;
  let routerType = 'push';
  switch (status) {
    case 400:
      break;
    case 401:
      routerPath = '/login';
      routerType = 'replace';
      break;
    case 403:
      routerPath = '/403';
      break;
    case 500:
    default:
      break;
  }

  if (!hideError) {
    message.error(`${msg} （${status} - ${traceId?.slice(0, 10)}）`);
  }

  if (routerPath) {
    router[routerType]({ path: routerPath }).catch(() => {});
  }
};

/**
 * axios初始化
 */
const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
});

/**
 * axios请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    config.headers.locale = getRequestHeaderLocale(i18n.global.locale.value);

    config.headers['X-Request-ID'] = String(new Date().getTime()).slice(-7);
    // 取消get缓存
    if (/get/i.test(config.method)) {
      config.params = config.params || {};
      config.params.t = Date.parse(new Date());
    }

    const userStore = useUserStore();
    if (userStore.accessToken) {
      config.headers[tokenName] = userStore.accessToken;
    }
    if (
      config.data &&
      config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = stringify(config.data);
    }
    if (config.headers.showLoading !== false) {
      showLoading();
    }
    return config;
  },
  (error) => {
    hideLoading();
    return Promise.reject(error);
  },
);

/**
 * axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      hideLoading();
      let { data, status, config } = response;
      let { code, businessCode, message: msg, traceId, trace } = data;
      if (businessCode) {
        code = businessCode['name'];
      }
      //  操作正常Code数组
      const codeVerificationArray = Array.isArray(successCode)
        ? [...successCode]
        : [...[successCode]];
      //  是否操作正常
      if (codeVerificationArray.includes(code)) {
        resolve(data);
      } else {
        handleCode(status, code, msg, config.headers.hideError, traceId, trace);
        reject(
          '星威养车请求异常拦截:' +
            JSON.stringify({ url: config.url, businessCode, msg }) || 'Error',
        );
      }
    });
  },
  async (error) => {
    console.log('err', error);
    hideLoading();
    const { message: errMsg, response } = error;
    if (error.response && error.response.data) {
      let {
        data: { code, businessCode, message: msg, traceId, trace },
        status,
        config,
      } = response;
      if (businessCode) {
        code = businessCode['name'];
      }
      handleCode(status, code, msg, config.headers.hideError, traceId, trace);
      return Promise.reject(error);
    } else {
      let status = null;
      let errorMsg = '';
      if (errMsg === 'Network Error') {
        errorMsg = '网络请求失败，请检查4G或WIFI是否连接正常。';
      } else if (errMsg.includes('timeout')) {
        errorMsg = '网络请求失败，请检查4G或WIFI是否连接正常。';
      } else if (errMsg.includes('Request failed with status code')) {
        status = Number(errMsg.substr(errMsg.length - 3));
        errorMsg =
          response?.data?.message ||
          response?.responseData?.message ||
          '网络请求失败，请检查4G或WIFI是否连接正常。';
        if (status === 401) {
          removeAccessToken();
          router.replace({ path: '/login' });
        }
      }
      handleCode(status, null, errorMsg, null, null, errMsg);
      return Promise.reject(error);
    }
  },
);

// =================新的请求实例=================
const http = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers.locale = getRequestHeaderLocale(i18n.global.locale.value);
    config.headers['Client-Type'] = 'Browser';
    config.headers['X-Request-ID'] = String(new Date().getTime()).slice(-7);
    // 取消get缓存
    if (/get/i.test(config.method)) {
      config.params = config.params || {};
      config.params.t = Date.parse(new Date());
    }
    const userStore = useUserStore();
    if (userStore.accessToken) {
      config.headers['token'] = userStore.accessToken;
    }
    if (
      config.data &&
      config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = stringify(config.data);
    }
    if (config.headers.showLoading !== false) {
      showLoading();
    }
    return config;
  },
  (error) => {
    hideLoading();
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      hideLoading();
      let {
        data: { code, businessCode, data, message: msg, traceId, trace },
        status,
        config,
      } = response;
      if (businessCode) {
        code = businessCode['name'];
      }
      //  操作正常Code数组
      const codeVerificationArray = Array.isArray(successCode)
        ? [...successCode]
        : [...[successCode]];
      //  是否操作正常
      if (codeVerificationArray.includes(code)) {
        resolve(data);
      } else {
        handleCode(status, code, msg, config.headers.hideError, traceId, trace);
        // 手动构造业务错误下错误对象，并提供响应相关结果
        if (code) {
          const error = new Error(msg);
          error.response = response;
          reject(error);
        }
      }
    });
  },
  async (error) => {
    hideLoading();
    const { message: errMsg, response } = error;

    if (response) {
      let {
        data: { code, businessCode, message: msg, traceId, trace },
        status,
        config,
      } = response;
      if (businessCode) {
        code = businessCode['name'];
      }

      handleCode(status, code, msg, config.headers.hideError, traceId, trace);
    } else {
      let status = null;
      let errorMsg = '';
      if (errMsg === 'Network Error') {
        errorMsg = '网络请求失败，请检查4G或WIFI是否连接正常。';
      } else if (errMsg.includes('timeout')) {
        errorMsg = '网络请求失败，请检查4G或WIFI是否连接正常。';
      } else if (errMsg.includes('Request failed with status code')) {
        status = Number(errMsg.substr(errMsg.length - 3));
        errorMsg =
          response?.data?.message ||
          response?.responseData?.message ||
          '网络请求失败，请检查4G或WIFI是否连接正常。';
      }
      handleCode(status, null, errorMsg, null, null, errMsg);
    }
    return Promise.reject(error);
  },
);
// =================新的请求实例=================

export { http };

export default instance;
