import axios from 'axios';

export function getAiChatConfig() {
  const now = new Date();
  // 一分钟内，从本地缓存获取
  const formattedTime = `${now.getFullYear()}-${String(
    now.getMonth() + 1,
  ).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}T${String(
    now.getHours(),
  ).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
  return axios({
    url: `https://oss.shinwell.cn/ai/ai-chat-config.json?v=${formattedTime}`,
    method: 'GET',
  });
}
