import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { message as AMessage } from 'ant-design-vue';
import 'ant-design-vue/es/message/style/index.js';

import AppRouter from '@/app-router.vue';
import router from './router';
import i18n from '@/locales/locales.js';

import directives from './directives/index.js';

import './styles/index.less';
import './router/permissions';

function setupEventListener() {
  // See https://vite.dev/guide/build.html#load-error-handling
  window.addEventListener('vite:preloadError', (event) => {
    AMessage.error('加载动态导入失败[vite:preloadError]');
    console.log('[vite:preloadError]', event);
    console.error('[vite:preloadError]', event.payload);
  });
}

setupEventListener();

createApp(AppRouter)
  .use(createPinia())
  .use(router)
  .use(directives)
  .use(i18n)
  .mount('#app');
