import { http } from '@/utils/request';

// 登录
export function login(data) {
  return http({
    url: '/v2/user/login',
    method: 'POST',
    data,
    headers: {
      hideError: true,
    },
  });
}

/**
 * 获取所有权限列表
 */
export function permissionList() {
  return http({
    url: '/v2/user/user-permission/list',
    method: 'GET',
  });
}

/**
 * 获取当前用户信息
 */
export function userInfo() {
  return http({
    url: '/v2/user/info',
    method: 'GET',
  });
}

/**
 * 获取用户列表
 */
export function userList(data) {
  return http({
    url: '/v2/user/query',
    method: 'POST',
    data,
  });
}

/**
 * 获取用户详情
 */
export function userDetails(userId) {
  return http({
    url: `/v2/user/detail/${userId}`,
    method: 'GET',
  });
}

/**
 * 获取车队长相关的车队小组
 * @param userId
 * @returns {AxiosPromise}
 */
export function queryVehicleGroupOptionsByCaptainUserId(userId) {
  return http({
    url: `/v2/vehicle/vehicle-team/group/options/captain/${userId}`,
    method: 'GET',
  });
}

/**
 * 锁定用户
 * @param userId 用户ID
 */
export function lockUser(userId) {
  return http({
    url: `/v2/user/lock/${userId}`,
    method: 'GET',
  });
}

/**
 * 解锁用户
 * @param userId 用户ID
 */
export function unlockUser(userId) {
  return http({
    url: `/v2/user/unlock/${userId}`,
    method: 'GET',
  });
}

/**
 * 创建用户
 * @param data
 */
export function createUser(data) {
  return http({
    url: '/v2/user/create',
    method: 'POST',
    data,
  });
}

/**
 * 更新用户
 * @param data
 */
export function updateUser(data) {
  return http({
    url: '/v2/user/update',
    method: 'POST',
    data,
  });
}

/**
 * 获取用户组列表
 */
export function userGroup() {
  return http({
    url: '/v2/user/user-group/list',
    method: 'GET',
  });
}

/**
 * 获取用户组详情
 * @param {string} userGroupId 用户组id
 */
export function userGroupDetails(userGroupId) {
  return http({
    url: `/v2/user/user-group/detail/${userGroupId}`,
    method: 'GET',
  });
}

/**
 * 查询用户组下用户列表
 * @param {Object} data 查询参数
 */
export function userGroupUserList(data) {
  const { userGroupId, pageNum, pageSize } = data;
  return http({
    url: `/v2/user/user-group/${userGroupId}/user/list/${pageNum}/${pageSize}`,
    method: 'GET',
  });
}

/**
 * 移除用户组中用户
 * @param {string} userGroupId 用户组ID
 * @param {string} userId 用户ID
 */
export function removeUser(userGroupId, userId) {
  return http({
    url: `/v2/user/user-group/${userGroupId}/user/remove/${userId}`,
    method: 'GET',
  });
}

/**
 * 根据用户姓名获取用户列表
 * @param {string} fullName 姓名
 */
export function getUserListByName(fullName) {
  return http({
    url: `/v2/user/list/full-name`,
    method: 'POST',
    data: fullName,
  });
}

/**
 * 添加用户到用户组
 * @param {string} userGroupId 用户组ID
 * @param {string} userId 用户ID
 */
export function addUserToGroup(userGroupId, userId) {
  return http({
    url: `/v2/user/user-group/${userGroupId}/user/add/${userId}`,
    method: 'GET',
  });
}

/**
 * 新建用户组
 * @param {Objetc} data 新建用户组数据
 */
export function createUserGroup(data) {
  return http({
    url: '/v2/user/user-group/create',
    method: 'POST',
    data,
  });
}

/**
 * 更新用户组
 * @param {Object} data 更新用户组数据
 */
export function updateUserGroup(data) {
  return http({
    url: '/v2/user/user-group/update',
    method: 'POST',
    data,
  });
}

/**
 * 获取用户权限列表
 */
export function permissionKeyList() {
  return http({
    url: '/v2/user/permission-key/list',
    method: 'GET',
    headers: { showLoading: false },
  });
}

/**
 * 获取机构超管账号详情
 * @param agencyId 车队ID/服务站ID
 */
export function agencyPresetUserDetail(agencyId) {
  return http({
    url: `/v2/user/agency/${agencyId}/preset-user/detail`,
    method: 'GET',
  });
}
export function agencyUserCount(agencyId) {
  return http({
    url: `/nv2/user/agency/${agencyId}/user-count`,
    method: 'GET',
  });
}

/**
 * 获取机构超管账号信息
 * @param agencyId 车队ID/服务站ID
 */
export function agencyPresetUserInfo(agencyId) {
  return http({
    url: `/v2/user/agency/${agencyId}/preset-user/info`,
    method: 'GET',
  });
}

/**
 * 编辑机构超管账号
 * @param data
 * @returns {*}
 */
export function editAgencyPresetUser(data) {
  return http({
    url: '/v2/user/agency/preset-user/edit',
    method: 'POST',
    data,
  });
}

// 查询用户400电话(详情)
export function userLandlineDetail(userId) {
  return http({
    url: `/v2/user/${userId}/landline/detail`,
    method: 'GET',
  });
}

// 查询用户400电话(回写)
export function userLandlineInfo(userId) {
  return http({
    url: `/v2/user/${userId}/landline/info`,
    method: 'GET',
  });
}

// 编辑用户400号码
export function userLandlineEdit(data) {
  return http({
    url: '/v2/user/landline/edit',
    method: 'POST',
    data,
  });
}

/**
 * 模糊检索指定机构用户列表
 * @param {string} agencyId 机构ID
 */
export function agencyUserListOptions(agencyId, keyword) {
  return http({
    url: `/v2/user/${agencyId}/user-list/options`,
    method: 'GET',
    params: { keyword },
  });
}

/**
 * 用户修改信息
 * @param {Object} data
 * @param {string} data.fullName 姓名
 * @param {string} data.email 邮箱
 */
export function editUserInfo(data) {
  return http({
    url: '/v2/user/fullname/edit',
    method: 'POST',
    data,
  });
}

/**
 * 获取验证码
 * @param data
 * @returns {*}
 */
export function userSendSms(data) {
  return http({
    url: '/v2/user/send/sms',
    method: 'POST',
    data,
  });
}

/**
 * 用户修改手机号
 * @param data
 * @returns {*}
 */
export function userMobilenumberEdit(data) {
  return http({
    url: '/v2/user/mobilenumber/edit',
    method: 'POST',
    data,
  });
}

/**
 * 查询用户是否已经绑定微信
 */
export function wxbindingStatus() {
  return http({
    url: '/v2/user/wxbinding/status',
    method: 'GET',
  });
}

/**
 * 用户绑定微信
 * @param {string} code 微信code
 */
export function wxbind(code) {
  return http({
    url: '/v2/user/wxbinding/bind',
    method: 'POST',
    data: { code, from: 1 },
  });
}

/**
 * 用户解绑微信
 */
export function wxunbind() {
  return http({
    url: '/v2/user/wxbinding/unbind',
    method: 'POST',
  });
}

/**
 * 用户修改密码
 * @param data
 * @returns {*}
 */
export function userPasswordEdit(data) {
  return http({
    url: '/v2/user/password/edit',
    method: 'POST',
    data,
  });
}

/**
 * 用户重置密码
 * @param data
 * @returns {*}
 */
export function userPasswordReset(data) {
  return http({
    url: '/v2/user/password/reset',
    method: 'POST',
    data,
  });
}

/**
 * 维保单审批设置-获取配置详情
 */
export function getMaintApprovalConfig(userId) {
  return http({
    url: `nv2/maint-approval-config/${userId}`,
    method: 'GET',
  });
}

/**
 * 维保单审批设置-是否可以进行审批设置（用户属于二审审批节点，或所在用户组属于二审审批节点）
 */
export function isMaintApprovalConfigEnabled(userId) {
  return http({
    url: `nv2/maint-approval-config/${userId}/enabled`,
    method: 'GET',
  });
}

/**
 * 维保单审批设置-保存审批设置
 */
export function updateMaintApprovalConfig(data) {
  return http({
    url: `nv2/maint-approval-config`,
    method: 'POST',
    data,
  });
}

// 获取排班记录
export function userSpecialDutyRosterList(params) {
  return http({
    url: '/nv2/user-special-duty-roster/list',
    params,
  });
}

/**
 * 通过姓名关键字获取总包用户可选项
 * @param {string} keyword 关键字
 * @param {boolean} onlyNormalStatus 仅查询正常状态
 */
export function fetchPlatformUserOptions(keyword, onlyNormalStatus) {
  return http({
    url: '/v2/user/platform-user/options',
    method: 'GET',
    params: { keyword, onlyNormalStatus },
  });
}

export function fetchUserListOptionsByFullName(keyword) {
  return http({
    url: '/v2/user/user-list/options/full-name',
    method: 'GET',
    params: { keyword },
  });
}

export function fetchUserListOptionsByUserName(keyword) {
  return http({
    url: '/v2/user/user-list/options/user-name',
    method: 'GET',
    params: { keyword },
  });
}

/**
 * 发送用户的账号密码
 * @param {string} userId 用户 Id
 * @returns
 */
export function sendSmsMessageWithAccountInfo(userId) {
  return http({
    url: `/v2/send/sms-message/${userId}/account`,
    method: 'GET',
    headers: {
      hideError: true,
    },
  });
}

/**
 * 通过手机号和机构 Id 查询车队司机信息可选项
 * @param {number} agencyId 机构 Id
 * @param {string} mobileNumber 手机号码
 * @returns
 */
export function fetchVehicleTeamUserOptionsByMobileNumber(
  agencyId,
  mobileNumber,
) {
  return http({
    url: `/v2/vehicle-team-driver/option/query`,
    method: 'POST',
    data: {
      agencyId,
      mobileNumber,
    },
  });
}

/**
 * 获取所属国际区号可选项
 * @returns
 */
export function fetchCallingcodeOptions() {
  return http({
    url: '/v2/phrase/callingcode/options',
    method: 'GET',
  });
}

/**
 * 设置用户备用金账户状态
 * @param data
 */
export function editUserPettyCashAccountStatus(data) {
  return http({
    url: '/v2/billing/user-petty-cash-account-status/edit',
    method: 'POST',
    data,
  });
}

/**
 * 查询用户备用金账户是否已初始化
 * @param data
 */
export function queryUserPettyCashAccountInitialized(userId) {
  return http({
    url: `/v2/billing/user-petty-cash-account-initialized/query/${userId}`,
    method: 'GET',
  });
}

/**
 * 新增用户信用额度申请
 * @param data
 */
export function addUserCreditApply(data) {
  return http({
    url: '/v2/billing/user-credit-apply/add',
    method: 'POST',
    data,
  });
}

/**
 * 获取用户信用额度申请列表
 * @returns
 */
export function queryUserCreditApply(userId) {
  return http({
    url: `/v2/billing/user-credit-apply/${userId}/query`,
    method: 'GET',
  });
}

/**
 * 审批通过用户信用额度申请
 * @param data
 */
export function approvedUserCreditApply(data) {
  return http({
    url: '/v2/billing/user-credit-apply/approved',
    method: 'POST',
    data,
  });
}

/**
 * 获取用户信息
 * @param {string} keyword 关键字
 * @returns
 */
export function getAccountInfo(keyword) {
  return http({
    url: `/v2/user/account-info/${keyword}`,
    method: 'GET',
  });
}
