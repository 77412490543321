export default {
  'stationAmercement.components.maintOrderNo': 'Order No.',
  'stationAmercement.components.amerceCreatedTime': 'Time of deduction payment',
  'stationAmercement.components.amerceAmount': 'Deduction Amount',
  'stationAmercement.components.deductedAmount': 'Offset deduction',
  'stationAmercement.components.remainingAmerceAmount': 'Remaining Penalty',
  'stationAmercement.components.amerceStatus': 'Status',
  'stationAmercement.components.image': 'Picture',
  'stationAmercement.components.preview': 'Preview',
  'stationAmercement.components.amercementReason': 'Reason for deduction',
  'stationAmercement.components.stationAmercementBillingTotal':
    'Total {total} entries',
};
