import antdEnUS from 'ant-design-vue/es/locale/en_US';
import dayjsEnUS from 'dayjs/esm/locale/en';

import appEnUS from '@/locales/en-US/app/app.js';
import homeEnUS from '@/locales/en-US/home/home.js';
import loginEnUS from '@/locales/en-US/login/login.js';
import systemLogEnUS from '@/locales/en-US/system-log/system-log.js';
import resetPasswordEnUS from '@/locales/en-US/reset-password/reset-password.js';
import maintEnUS from '@/locales/en-US/maint/maint.js';
import stationMonthlyStatementEnUS from '@/locales/en-US/station-monthly-statement/station-monthly-statement.js';
import accountManageEnUS from '@/locales/en-US/account-manage/account-manage.js';
import userEnUS from '@/locales/en-US/user/user.js';
import permissionManageEnUS from '@/locales/en-US/permission-manage/permission-manage.js';
import vehicleTeamEnUS from '@/locales/en-US/vehicle-team/vehicle-team.js';
import vehicleManagementEnUS from '@/locales/en-US/vehicle-management/vehicle-management.js';
import stationAmercementEnUS from '@/locales/en-US/station-amercement/station-amercement.js';
import stationMonthlyStatementConfirmationEnUS from '@/locales/en-US/station-monthly-statement-confirmation/station-monthly-statement-confirmation.js';
import directSupplyManageEnUS from '@/locales/en-US/direct-supply-manage/direct-supply-manage.js';
import billingEnUS from '@/locales/en-US/billing/billing.js';
import vehicleTeamMonthlyInspectionReportEnUS from '@/locales/en-US/vehicle-team-monthly-inspection-report/vehicle-team-monthly-inspection-report.js';
import vehicleInspectionReportEnUS from '@/locales/en-US/vehicle-inspection-report/vehicle-inspection-report.js';

const components = {
  localeAntd: antdEnUS,
};

const plugin = {
  localeDayjs: dayjsEnUS,
};

const pages = {
  ...appEnUS,
  ...homeEnUS,
  ...loginEnUS,
  ...systemLogEnUS,
  ...resetPasswordEnUS,
  ...maintEnUS,
  ...stationMonthlyStatementEnUS,
  ...accountManageEnUS,
  ...userEnUS,
  ...permissionManageEnUS,
  ...vehicleTeamEnUS,
  ...vehicleManagementEnUS,
  ...stationAmercementEnUS,
  ...stationMonthlyStatementConfirmationEnUS,
  ...directSupplyManageEnUS,
  ...billingEnUS,
  ...vehicleTeamMonthlyInspectionReportEnUS,
  ...vehicleInspectionReportEnUS,
};

export default {
  ...components,
  ...plugin,
  ...pages,
};
